<template>
  <div>
    <div class="modal-container">
      <Form mode="block" :model="form" :rules="validationRules" ref="form">
        <FormItem label="账号" prop="username">
          <input type="text" v-model="form.username">
        </FormItem>
        <FormItem label="密码" prop="password" v-if="!form.id">
          <input type="password" v-model="form.password">
        </FormItem>
        <FormItem label="姓名" prop="nickname">
          <input type="text" v-model="form.nickname">
        </FormItem>
        <FormItem label="电话" prop="mobile">
          <input type="text" v-model="form.mobile">
        </FormItem>
        <FormItem label="角色" prop="roleType">
          <Radio dict="roleType" v-model="form.roleType"/>
        </FormItem>
        <FormItem label="状态" prop="enable">
          <Radio dict="state" v-model="form.enable"/>
        </FormItem>
      </Form>
    </div>
    <div class="h-modal-footer">
      <Button :loading="isLoading" @click="$emit('close')" icon="fa fa-close">取消</Button>
      <Button :loading="isLoading" @click="save()" color="primary" icon="fa fa-save">保存</Button>
    </div>
  </div>
</template>

<script>
import SystemUser from "@/api/SystemUser";

export default {
  name: "SysUserForm",
  props: {
    entity: Object
  },
  data() {
    return {
      form: {
        id: null,
        nickname: '',
        username: '',
        password: '',
        roleType: 'OPERATOR',
        mobile: '',
        enable: true,
      },
      validationRules: {
        required: ['nickname', 'username'],
        mobile: ['mobile']
      },
      isLoading: false
    }
  },
  methods: {
    save() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.isLoading = true;
        SystemUser.save(this.form).then(({data}) => {
          this.$emit('success');
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }
  },
  created() {
    Utils.copyObj(this.form, this.entity)
  }
}
</script>

<style scoped>

</style>
